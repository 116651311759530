import React, { useState } from 'react';
import styles from './styles/CustomCalendar.module.css';
import moment from 'moment';
import AllDayEventsModal from './components/AllDayEventsModal';
import EventDetailModal from './components/EventDetailModal';
import { CaretRightOutlined, CaretLeftOutlined, PrinterOutlined, ShareAltOutlined, FilterFilled } from "@ant-design/icons";
import { calendarHandle } from './state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, message, Radio, Spin,Tooltip } from 'antd';
import { SignableAndReadOnly } from '../Common/components/SignableAndReadOnly';
import { TaskInfo } from './components/TaskInfo';
import { useMediaQuery } from 'react-responsive';
import FilterModal from './components/FilterModal';
import axios from 'axios';
import { transactionLaneDataHandle } from '../Rtd/state/actions';

function CustomCalendar({ events, source, documentId, calendarDataLoading, isInWebView, calendarDataEncrypt , shareCalendarLoading}) {
 
  const today = moment();
  
  let calendarDate = calendarDataEncrypt?.persistCalendar?.date?.split("-")
  const dispatch = useDispatch();
  const [dateState, setDateState] = useState({
    currentMonth: (calendarDate?.length === 3) ? Number(calendarDate?.[1]) : today.month(),
    currentYear: (calendarDate?.length === 3) ? Number(calendarDate?.[0]) : today.year(),
  });
  const [viewMode, setViewMode] = useState("COMPACT");
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  // Filter events based on type "CONTRACT_REMINDER" for transaction calendar
  const filteredEvents = source === 'TRANSACTION_CALENDAR' 
  ? events?.filter(event => ['CONTRACT_REMINDER', 'CONTRACT_SCHEDULE'].includes(event?.type))
  : events;


  let startMonth, endMonth, startYear, endYear;

  // Determine the month range based on the source type
  if (source === 'TRANSACTION_CALENDAR') {
    // Find the lowest and highest eventMonth
    const years = filteredEvents?.map(event => Number(event?.eventYear));
    startYear = Math.min(...years);
    endYear = Math.max(...years);

    if (startYear === endYear) {
      const months = filteredEvents?.map(event => Number(event?.eventMonth) - 1);
      startMonth = Math.min(...months);
      endMonth = Math.max(...months);
    } else {
      const monthsOfStartYear = filteredEvents?.filter(event => Number(event.eventYear) === startYear)
        .map(event => Number(event.eventMonth) - 1); // Convert months to 0-indexed
      startMonth = Math.min(...monthsOfStartYear);  // Get the earliest month in startYear

      // Get months of events in the endYear
      const monthsOfEndYear = filteredEvents?.filter(event => Number(event.eventYear) === endYear)
        .map(event => Number(event.eventMonth) - 1); // Convert months to 0-indexed
      endMonth = Math.max(...monthsOfEndYear);
    }
  } else if (source === 'HOME_CALENDAR') {
    // Show only one month for HOME_CALENDAR
    startMonth = dateState?.currentMonth;
    endMonth = dateState?.currentMonth;
    startYear = dateState?.currentYear;
    endYear = dateState?.currentYear;
  } else {
    // Default to 12 months for other sources
    startMonth = 0; // January
    endMonth = 11;  // December
  }

  // Filter events based on the date range
  // const allEventsOnRange = events.filter(event => {
  //   const eventMonth = Number(event.eventMonth) - 1; // Correcting for zero-indexed months
  //   return eventMonth >= startMonth && eventMonth <= endMonth;
  // });

  // Create calendars for the range of months
  const calendars = [];
  let diff;
  if (startYear === endYear) {
    // If the startYear and endYear are the same, the difference is just between the months
    diff = endMonth - startMonth;
  } else {
    // If the startYear and endYear are different, calculate based on multiple years
    const monthsInStartYear = 11 - startMonth; // Months remaining in the startYear from startMonth to December
    const monthsInEndYear = endMonth + 1; // Months from January to endMonth (inclusive) in endYear
    const fullYearsInBetween = (endYear - startYear - 1) * 12; // Full years between startYear and endYear (if any)

    // The total diff is the sum of months in the start year, months in the end year, and full years in between
    diff = monthsInStartYear + monthsInEndYear + fullYearsInBetween;
  }

  // Check if diff is Infinity or -Infinity and set it to 1 if so
  if (!isFinite(diff)) {
    diff = 1;
    startMonth = dateState?.currentMonth;
    endMonth = dateState?.currentMonth;
    startYear = dateState?.currentYear;
    endYear = dateState?.currentYear;
  }

  for (let i = 0; i <= diff; i++) {

    let month = (startMonth + i) % 12;
    let year = startYear + Math.floor((startMonth + i) / 12);

    calendars.push(
      <Calendar
        key={month}
        month={month}
        year={year}
        events={
          source === "TRANSACTION_CALENDAR" ? events?.filter(event => Number(event.eventMonth) - 1 === month && Number(event.eventYear) === year && ['CONTRACT_REMINDER', 'CONTRACT_SCHEDULE'].includes(event?.type))
            : source === "HOME_CALENDAR" ?
              events?.filter(event => {
                const eventMonth = event?.eventMonth ? Number(event.eventMonth) - 1 : moment(event.startTimestamp).month();
                const eventYear = event?.eventYear ? Number(event.eventYear) : moment(event.startTimestamp).year();
                return eventMonth === month && eventYear === year;
              })
              : events
        }
        currentMonth={dateState?.currentMonth}
        setDateState={setDateState}
        source={source}
        documentId={documentId}
        calendarDataLoading={calendarDataLoading}
        viewMode={viewMode}
        isInWebView={isInWebView}
        calendarDataEncrypt={calendarDataEncrypt}
      />
    );
  }

  const handlePrint = () => {
    setViewMode("EXPANDED");
    setTimeout(() => {
      window.print();
    }, 0);
  };

  const onViewChange = (e) => {
    setViewMode(e?.target?.value)
  }

  const handleShare = () => {
    let unmounted = {
      value: false,
    };
    let sourceAxios = axios.CancelToken.source();
    if (calendarDataEncrypt?.offerId && calendarDataEncrypt?.key) {
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId: calendarDataEncrypt?.offerId, unmounted, sourceAxios, userAuthKey: calendarDataEncrypt?.key }));
    }
    setIsEmailModalVisible(true);
  };

 
  return (
    <div style={{backgroundColor: "white", position: "relative"}} className={styles.calendarWrapperContainer}>
      {
        !calendarDataLoading && <div className={styles.viewModeOptions}>
          <Radio.Group className={styles.hideOnPrint} onChange={onViewChange} value={viewMode}>
            <Radio value={"COMPACT"}>Compact view</Radio>
            <Radio value={"EXPANDED"}>Expanded view</Radio>
          </Radio.Group>
          {!isInWebView && <Button className={styles.printBtn} size='small' onClick={handlePrint}><PrinterOutlined />Print</Button>}
          {(source !== 'HOME_CALENDAR') && (!calendarDataEncrypt?.screen || !["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(calendarDataEncrypt?.screen)) && <Button className={`${styles.shareBtn} ${isInWebView ? styles.shareBtnInWebView : ''}`} size='small' onClick={handleShare}>{shareCalendarLoading && <span><Spin size='small'/></span>}<ShareAltOutlined />Share</Button>}
        </div>
      }
      <div className={styles.calendarWrapper}>
        <div className={styles.printTitleAndPropertyAddressCont}>
          <h2>CALENDAR</h2>
          {source === "TRANSACTION_CALENDAR" && filteredEvents?.[0]?.address?.fullAddress && <h2>{filteredEvents?.[0]?.address?.fullAddress}</h2>}
        </div>
        {calendars}
      </div>
      
      {/* Open send modal for sharing the calender to the clients */}
      {(isEmailModalVisible) &&
        <SignableAndReadOnly
          source={source}
          isEmailModalVisible={isEmailModalVisible}
          userAuthKey={calendarDataEncrypt?.key}
          setIsEmailModalVisible={setIsEmailModalVisible}
          offerId={calendarDataEncrypt?.offerId}
          rootDocData={calendarDataEncrypt}
          propertyAddress={filteredEvents?.[0]?.address?.fullAddress}
        />
      }
    </div>
  );
}

export default CustomCalendar;

const Calendar = ({ month, year, events, source, currentMonth, setDateState, documentId, calendarDataLoading, viewMode, isInWebView, calendarDataEncrypt }) => {
  const [modalStates, setModalStates] = useState({
    openDetailModal: false,
    openAllEventsModal: false,
    openFilterModal: false,
    dayEvents: [],
    event: {}
  });

  const [showDatePicker, setShowDatePicker] = useState(false); 
  const breakPoint = useMediaQuery({ maxWidth: "573px" });
  const daysInMonth = moment({ year, month }).daysInMonth();
  const firstDay = moment({ year, month, day: 1 }).day();
  const days = [...Array(daysInMonth).keys()].map(i => i + 1);
  const today = moment();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(moment());
  let { calenderMonthFilter } = useSelector((state) => state.calendarData);
  const onDateChange = (date) => {
   setSelectedDate(date);

    const selectedMonthTimestamp = moment().year(date.year()).month(date.month()).valueOf();
    setDateState(prev => ({
      ...prev,
      currentMonth: date.month(),
      currentYear: date.year(),
    }));
    dispatch(calendarHandle.getCalendarDetails({
      dataObject: { key: calendarDataEncrypt.key },
      timestamp: selectedMonthTimestamp,
      responseType: "MONTH",
    }));
    setShowDatePicker(false); // Close DatePicker after selection
  };
  // Handle next month navigation
  const handleNextMonth = () => {
    const nextMonth = moment().year(year).month(currentMonth).add(1, 'month'); // Add 1 month
    const nextMonthTimestamp = nextMonth.valueOf(); // Get the timestamp for the next month
    setDateState(prev => ({
      ...prev,
      currentMonth: (prev.currentMonth + 1) % 12, // Increment currentMonth, wrap around to 0 after December
      currentYear: prev.currentMonth === 11 ? prev.currentYear + 1 : prev.currentYear // Increment year if month wraps to 0
    }));

    // setDateState(prev => ({...prev, currentMonth: (currentMonth + 1) % 12})); // Increment currentMonth by 1
    dispatch(calendarHandle.getCalendarDetails({dataObject: { key: calendarDataEncrypt.key }, timestamp: nextMonthTimestamp, responseType: "MONTH"}));
  };

  // Handle previous month navigation
  const handlePrevMonth = () => {
    const prevMonth = moment().year(year).month(currentMonth).subtract(1, 'month'); // Subtract 1 month
    const prevMonthTimestamp = prevMonth.valueOf(); // Get the timestamp for the previous month
    setDateState(prev => ({
      ...prev,
      currentMonth: prev.currentMonth === 0 ? 11 : prev.currentMonth - 1, // Decrement currentMonth, wrap around to 11 after January
      currentYear: prev.currentMonth === 0 ? prev.currentYear - 1 : prev.currentYear // Decrement year if month wraps to 11
    }));
    // setDateState(prev => ({...prev, currentMonth: currentMonth === 0 ? 11 : prev - 1})); // Increment currentMonth by 1
    dispatch(calendarHandle.getCalendarDetails({dataObject: { key: calendarDataEncrypt.key }, timestamp: prevMonthTimestamp, responseType: "MONTH"}));
  };

  const handleCurrentMonth = () => {
    setModalStates({ ...modalStates, openDetailModal: false, openFilterModal: false })
    const current = moment().year(year).month(currentMonth);
   
    const currentMonthTimestamp = current.valueOf(); // Get the timestamp for the current month

    dispatch(calendarHandle.getCalendarDetails({dataObject: { key: calendarDataEncrypt.key, ...(source !== "HOME_CALENDAR" ? {offerId: calendarDataEncrypt.offerId, openFor: calendarDataEncrypt.openFor} : {}) }, timestamp: currentMonthTimestamp, responseType: source === "HOME_CALENDAR" ? "MONTH" : "YEAR"}));
  };

  const handlesetCurrentMonth = () => {
    // Get the current month and year dynamically
    const current = moment().startOf('month'); // Get the start of the current month
    const currentMonthTimestamp = current.valueOf(); // Get the timestamp for the start of the current month
  
    setDateState(prev => ({
      ...prev,
      currentMonth: moment().month(), // Reset to the current month
      currentYear: moment().year() // Reset to the current year
    }));
    dispatch(calendarHandle.getCalendarDetails({
      dataObject: { key: calendarDataEncrypt.key },
      timestamp: currentMonthTimestamp,
      responseType: "MONTH"
    }));
  };

  const handleRedirect = ({year, month, day}) => {
    if(isInWebView){
      let mobileDataToSend = {
        eventType: "REDIRECT_TO_DAY_VIEW",
        date: year + "-" + month + "-" + day
      }
      window?.ReactNativeWebView?.postMessage(JSON.stringify(mobileDataToSend));
    }
    else{
      window?.parent?.postMessage(
        { action: "navigate", route: `/calendar/${year}-${month}-${day}` },
        "*"
      );
    }
  }

  // Create a grid for the days
  const grid = [];
  for (let i = 0; i < firstDay; i++) {
    grid.push(<div className={styles.empty} key={`empty-${i}`} />);
  }
  for (let day of days) {
    const dateString = moment({ year, month, day }).format('MM/DD/YYYY');
    
    let dayEvents = events?.filter(event => moment(event.eventDate || event.startTimestamp).date() === day);
    
    if(source === "HOME_CALENDAR"){
      let objEvent = {};
      for(let key of dayEvents){
      
        let fullAddress = key.address.fullAddress
        if(objEvent[fullAddress] !== undefined){
          objEvent[fullAddress] = {
            ...objEvent[fullAddress],
            fullAddress,
            tasks: [...objEvent[fullAddress].tasks, {
              title: key?.title,
              description: key?.description,
              clientIds: key.clientIds,
              agentIds: key.agentIds,
              address:key.address,
              ClosingDate:key.ClosingDate,
              _id: key?._id,
              type: key?.dashboardType,
              dateOrDeadline: moment(key?.dateOrDeadline),
              completedDateMili: key?.completedDateMili ? moment(key?.completedDateMili) : null
            }]
          };
        }
        else{
          objEvent[fullAddress] = {
            ...key,
            fullAddress,
            tasks: [{
              title: key?.title,
              description:  key?.description,
              _id: key?._id,
              clientIds: key.clientIds,
              agentIds: key.agentIds,
              address:key.address,
              ClosingDate:key.ClosingDate,
              type: key?.dashboardType,
              dateOrDeadline: moment(key?.dateOrDeadline),
              completedDateMili: key?.completedDateMili ? moment(key?.completedDateMili) : null
            }]
          }
        }
      }
      dayEvents = [];
      for(let obj in objEvent){
        dayEvents.push(objEvent[obj]);
      }
    }
 
    grid.push(
      <div className={`${styles.day} ${today.isSame(moment({ year, month, day }), "day") ? styles.today : ""}`} key={day} data-date={dateString} style={source === "HOME_CALENDAR" ? { padding: "2px" } : {}}>
        <div className={`${styles.dayNumber} ${(source === "HOME_CALENDAR") ? styles.homeDayNumber : ""}`} {...(source === "HOME_CALENDAR" && { onClick: ()=>handleRedirect({year, month: month+1, day}) })}>{day}</div>
        <div className={styles.eventContainer}>
          {dayEvents?.map((event, index) => {
            const now = moment();
            const dateOrDeadline = moment(event?.dateOrDeadline);
            const completedDateMili = event?.completedDateMili ? moment(event?.completedDateMili) : null;

            let incompleteEvent = false;
            let completedEvent = false;
            let upcomingEvent = false;

            if (["REMINDER", "SCHEDULE"].includes(event?.type)) {
              const startTimestamp = moment(event?.startTimestamp);
              const endTimestamp = moment(event?.endTimestamp);
              if (endTimestamp?.isBefore(now)) {
                completedEvent = true;
              } else if (startTimestamp?.isBefore(now)) {
                upcomingEvent = true;
              }
            } else {
              if (dateOrDeadline.isBefore(now) && !completedDateMili) {
                // Deadline passed and task not completed
                incompleteEvent = true;
              } else if (completedDateMili && completedDateMili.isBefore(now)) {
                // Task completed
                completedEvent = true;
              } else if (dateOrDeadline.isAfter(now) && !completedDateMili) {
                // Deadline not passed and task not completed
                upcomingEvent = true;
              }
            }

            const handleTransactionRedirect = () => {
              let persistCalendar = {
                section: "month",
                date: year + "-" + month + "-" + day,
                agentIds: calenderMonthFilter?.filter((element)=> element.selected)?.map((element)=> element._id)
              }
              if(isInWebView){
                if(event.isDelegateAccess){
                  message.error("Currently there is no TC Dashboard in Mobile App.")
                }
                else{
                  let mobileDataToSend = {
                    eventType: "REDIRECT_TO_TRANSACTIONS",
                    categoryId: (event.openFor === "BUYER_AGENT") ? event.offerId : event.propertyId,
                    category: (event.openFor === "BUYER_AGENT") ? "BUYER" : "SELLER",
                    persistCalendar
                  }
                  window?.ReactNativeWebView?.postMessage(JSON.stringify(mobileDataToSend));
                }
              }
              else{
                const route = event.isDelegateAccess ? "/account/delegates/transactions" : "/transactions"
                const state = {
                  categoryId: (event.openFor === "BUYER_AGENT") ? event.offerId : event.propertyId,
                  category: (event.openFor === "BUYER_AGENT") ? "BUYER" : "SELLER"
                }
                window?.parent?.postMessage(
                  { action: "navigate", persistCalendar, route, state  },
                  "*"
                );
              }
            }

            return (
              <div
                key={index}
                {...(source !== "HOME_CALENDAR" && { onClick: () => setModalStates({ event, openDetailModal: true }) })}
                className={(source !== "HOME_CALENDAR" ? `${styles.eventTitle} 
            ${event?.eventType === "PUBLIC HOLIDAY" ? styles.holiday : (source === "HOME_CALENDAR" ? event?.tasks?.filter((ele) => ele?.title === "Closing Date")?.length : event?.title === "Closing Date") ? styles.closingDate : completedEvent ? styles.completedEvent : incompleteEvent ? styles.incompleteEvent : upcomingEvent ? styles.upcomingEvent : ""}` : `${styles.homeEvent}`)}
                style={
                  viewMode === "EXPANDED"
                    ? {
                        lineHeight: "normal",
                        overflow: "unset",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "unset",
                      }
                    : {}
                }
              >
                {source === "HOME_CALENDAR" ? (
                  <div className={styles.titleCard}>
                    <div className={styles.cardFullAddressHyperLink} onClick={handleTransactionRedirect}>{event.fullAddress}</div>
                    {Array.isArray(event?.tasks) &&
                      event.tasks?.map((task) => {
                        incompleteEvent = false; completedEvent = false; upcomingEvent = false;
                        if (task.dateOrDeadline.isBefore(now) && !task.completedDateMili) {
                          // Deadline passed and task not completed
                          incompleteEvent = true;
                        } else if (task.completedDateMili && task.completedDateMili.isBefore(now)) {
                          // Task completed
                          completedEvent = true;
                        } else if (task.dateOrDeadline.isAfter(now) && !task.completedDateMili) {
                          // Deadline not passed and task not completed
                          upcomingEvent = true;
                        }
                        return (
                          breakPoint ? <div
                            className={(`${styles.homeTitle} 
                              ${task?.title === "Closing Date" ? styles.closingDate : completedEvent ? styles.completedEvent : incompleteEvent ? styles.incompleteEvent : upcomingEvent ? styles.upcomingEvent : ""}`)}
                            onClick={() => {
                              setModalStates({ event: { ...event, eventName: task.title, description: task.description, completedDateMili : task?.completedDateMili, _id: task._id, type: task.type, handleTransactionRedirect }, openDetailModal: true });
                            }}
                          >
                            {" "}
                            • {task.title}
                          </div> :
                          <Tooltip overlayClassName="customTooltip" title={<TaskInfo event={{ ...task, fullAddress: event.fullAddress }} source={source} handleTransactionRedirect={handleTransactionRedirect}/>} placement="top">
                            <div
                              className={(`${styles.homeTitle} 
                                ${task?.title === "Closing Date" ? styles.closingDate : completedEvent ? styles.completedEvent : incompleteEvent ? styles.incompleteEvent : upcomingEvent ? styles.upcomingEvent : ""}`)}
                              onClick={() => {
                                setModalStates({ event: { ...event, eventName: task.title, description: task.description, completedDateMili : task?.completedDateMili, _id: task._id, type: task.type, handleTransactionRedirect }, openDetailModal: true });
                              }}
                            >
                              {" "}
                              • {task.title}
                            </div>
                          </Tooltip>
                        )
                      })}
                  </div>
                ) : (
                  <Tooltip overlayClassName="customTooltip" title={<TaskInfo event={event} />} placement="top">
                    {event.title}
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
 

  return (
    <>
      {calendarDataLoading ? (
        <div className={styles.centerLoader}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.calendar} style={isInWebView || calendarDataEncrypt?.screen === "CLIENT_SCREEN" ? { width: "100%" } : {}}>
            <div className={styles.header} style={source === "HOME_CALENDAR" ? { position: "sticky", top: "32px" } : {}}>
              {source === "HOME_CALENDAR" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div onClick={()=>setModalStates({ ...modalStates, openFilterModal: true })}>
                    <Button
                      style={{
                        background: "#3a74b6",
                        color: "whitesmoke",
                        fontWeight: "500",
                        border: "none",
                        fontSize: "13px",
                        height: "30px",
                        position: "absolute",
                        marginTop: "5px",
                        marginLeft: breakPoint?"-90px":"-115px",
                      }}
                      size={breakPoint?"small":"medium"}
                      icon={
                        <FilterFilled
                          style={{
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      }
                   className={styles.noPrint}
                    >
                      <span
                        style={{
                          border: "1.5px solid white",
                          alignItems: "center",
                          margin: "0px 5px -1.5px 5px",
                          height: "12px",
                        }}
                        ></span>
                      Filter
                    </Button>
                  </div>
                  <div className={styles.headerTools}>
                    <span className={styles.arrow} onClick={handlePrevMonth}>
                      <CaretLeftOutlined />
                    </span> 
                    <DatePicker
                      picker="month"
                      open={showDatePicker} 
                      format="MMMM YYYY" 
                      style={{ visibility: "hidden", width: "0px" }}
                      onOpenChange={(open) => setShowDatePicker(open)}
                      onChange={onDateChange}
                      onClickOutside={() => setShowDatePicker(false)}
                    />
                     {(
                      <span className={styles.monthTitle} onClick={() => setShowDatePicker(true)} style={{cursor:source === 'HOME_CALENDAR'?"pointer":"none"}}>
                        {moment({ year, month: currentMonth }).format("MMMM YYYY")}
                      </span>
                     )}
                    <span className={styles.arrow} onClick={handleNextMonth}>
                      <CaretRightOutlined />
                    </span>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      size={breakPoint?"small":"middle"}
                      style={{
                        borderRadius: "2px",
                        marginTop: "5px",
                        fontSize: "small",
                        backgroundColor: ((currentMonth === moment().month()) && (year === moment().year())) ? "gray" : "#3a74b6", // Gray if same, blue otherwise
                        height: "30px",
                        marginLeft: breakPoint?"20px":"30px",
                        position: "absolute"
                      }}
                      {...(((currentMonth === moment().month()) && (year === moment().year())) ? {} : {onClick: handlesetCurrentMonth})}
                    >
                      {" "}
                      {breakPoint ? "Reset" : "Reset to Current Month"}
                    </Button>
                  </div>
                </div>
              ) : (
                moment({ year, month }).format("MMMM YYYY")
              )}
            </div>
            <>
              <div className={styles.weekdays} style={source === "HOME_CALENDAR" ? { position: "sticky", top: "71px" } : {}}>
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                  <div className={styles.weekday} key={day}> 
                    {day}
                  </div>
                ))}
              </div>
              <div className={styles.daysGrid}>{grid}</div>
            </>
          </div>
          {modalStates?.openAllEventsModal && <AllDayEventsModal setModalStates={setModalStates} modalStates={modalStates} />}
          {modalStates?.openDetailModal && <EventDetailModal setModalStates={setModalStates} handleCurrentMonth={handleCurrentMonth} modalStates={modalStates} calendarDataEncrypt={calendarDataEncrypt} source={source}/>}
          {modalStates?.openFilterModal && <FilterModal setModalStates={setModalStates} handleCurrentMonth={handleCurrentMonth}/>}
        </>
      )}
    </>
  );
};
