export const transactionLaneConstants = {
  GET_TRANSACTION_LANE_IN_RTD_SUCCESS: "GET_TRANSACTION_LANE_IN_RTD_SUCCESS",
  GET_TRANSACTION_LANE_IN_RTD_FAILURE: "GET_TRANSACTION_LANE_IN_RTD_FAILURE",
  GET_TRANSACTION_LANE_IN_RTD_LOADING: "GET_TRANSACTION_LANE_IN_RTD_LOADING",

  GET_INDIVIDUAL_TRANSACTION_LOADING:"GET_INDIVIDUAL_TRANSACTION_LOADING",
  GET_INDIVIDUAL_TRANSACTION_SUCCESS: "GET_INDIVIDUAL_TRANSACTION_SUCCESS",
  GET_INDIVIDUAL_TRANSACTION_FAILURE: "GET_INDIVIDUAL_TRANSACTION_FAILURE",
  TRIGGER_INDIVIDUAL_TRANSACTION_FETCH: "TRIGGER_INDIVIDUAL_TRANSACTION_FETCH",


  // Email action Type
  SEND_EMAIL_LOADING: "SEND_EMAIL_LOADING",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",


  // GET_DORA_DOCUMENTS_LOADING
  GET_DORA_DOCUMENTS_LOADING: "GET_DORA_DOCUMENTS_LOADING",
  GET_DORA_DOCUMENTS_SUCCESS: "GET_DORA_DOCUMENTS_SUCCESS",
  GET_DORA_DOCUMENTS_FAILURE: "GET_DORA_DOCUMENTS_FAILURE",

  GET_EMAIL_TEMPLATE_LOADING: "GET_EMAIL_TEMPLATE_LOADING",
  GET_EMAIL_TEMPLATE_SUCCESS: "GET_EMAIL_TEMPLATE_SUCCESS",
  GET_EMAIL_TEMPLATE_FAILURE: "GET_EMAIL_TEMPLATE_FAILURE",

  DORADOCS_SHORTHAND_URL_LOADING: "DORADOCS_SHORTHAND_URL_LOADING",
  DORADOCS_SHORTHAND_URL_SUCCESS: "DORADOCS_SHORTHAND_URL_SUCCESS",
  DORADOCS_SHORTHAND_URL_FAILURE: "DORADOCS_SHORTHAND_URL_FAILURE",

  UPDATE_EMAIL_FAILURE:"UPDATE_EMAIL_FAILURE",
  UPDATE_EMAIL_SUCCESS:"UPDATE_EMAIL_SUCCESS",

  CLIENT_DETAILS_LOADING:"CLIENT_DETAILS_LOADING",
  CLIENT_DETAILS_FAILURE:"CLIENT_DETAILS_FAILURE",
  CLIENT_DETAILS_SUCCESS:"CLIENT_DETAILS_SUCCESS",

  CLIENT_LIST_LOADING:"CLIENT_LIST_LOADING",
  CLIENT_LIST_FAILURE:"CLIENT_LIST_FAILURE",
  CLIENT_LIST_SUCCESS:"CLIENT_LIST_SUCCESS",

  ADD_TRANSACTION_MAIL_LOADING:"ADD_TRANSACTION_MAIL_LOADING",
  ADD_TRANSACTION_MAIL_FAILURE:"ADD_TRANSACTION_MAIL_FAILURE",
  ADD_TRANSACTION_MAIL_SUCCESS: "ADD_TRANSACTION_MAIL_SUCCESS",
  
  GET_SHARED_DOCS_LOADING: "GET_SHARED_DOCS_LOADING",
  GET_SHARED_DOCS_SUCCESS: "GET_SHARED_DOCS_SUCCESS",
  GET_SHARED_DOCS_FAILURE: "GET_SHARED_DOCS_FAILURE",

  ADD_EMAIL_LOADING: "ADD_EMAIL_LOADING",
  ADD_EMAIL_ERROR: "ADD_EMAIL_ERROR",
  ADD_EMAIL_SUCCESS: "ADD_EMAIL_SUCCESS",
};
