import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const transactionLaneApi = (data, role,userAuthKey) => {

  return axios.get(`${apiURL}/RTD/${data}?role=${role}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${userAuthKey}`,
    },
  });
};


const updateDocumentApi = ({documentId, data, userAuthKey, emailSignatureId, emailMessage}) => {
 
  let apiUrl = `${apiURL}/offerDocument/${documentId}`;
  if (!data?.sellerAgentSigned) {
    delete data?.sellerAgentSigned;
  }
  return axios.put(
    apiUrl,
    {
      ...data,
       emailSignatureId,
       emailMessage
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${userAuthKey}`,
      },
    }
  );
}

const sendEmailToClientRequest = (data,userAuthKey) => {
  return axios.post(`${apiURL}/offerDocument/sendDocument`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${userAuthKey}`,
    },
  });
};

const getNestedDoraDocuments = (data, accessToken) => {
  return axios.get(
    `${apiURL}/nestedContractDocumentaries?listType=${data.listType}&offerId=${data.offerId}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

const getemailtemplateRequest = (userAuthKey) => {
  return axios.get(`${apiURL}/docAndClause/EMAIL_TEMPLATE`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${userAuthKey}`,
    },
  });
};
const urlShorthandApi = (data={}) => {
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${data.token}`,
      },
    }
  );
};

// Update email
const updateEmailRequest = (values,personId,offerTableAuthKey) => {
    // let accessTokan = localStorage.getItem("auth");
    const params = new URLSearchParams();
    params.append("personId", personId);
    params.append("email", values.email);

    return axios.put(
      `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
      params,
      {
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${offerTableAuthKey}`,
        },
      }
    );
  };

  const addEmailRequest = (accessTokan, data) => {
    return axios.post(`${apiURL}/addClientDetail`,{...data},{
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
      },
    })
  }
  // Update Transaction email
const updateTransactionEmailRequest = (email,sellerDetail,offerId,offerTableAuthKey) => {
    let data ={};
    data.email = sellerDetail.email;
    data.fullName = sellerDetail.fullName;
    data.key = sellerDetail.key;
    const params = {};
    params.addToProperty = false;
    params.offerId = offerId;
    params.sellerDetail ={...data,email};

    return axios.post(
      `${apiURL}/addClientDetail`,
      params,
      {
        headers: {
         // "Content-Type": "application/x-www-form-urlencoded",
         // accept: "application/json",
          authorization: `bearer ${offerTableAuthKey}`,
        },
      }
    );
  };
  const getSharedDocsRequest = ({offerId, userAuthKey}) => {
    return axios.get(`${apiURL}/getSharedDocs`,
    {
      params: {
        offerId,
      },
      headers: {
        accept: "application/json",
        authorization: `bearer ${userAuthKey}`,
      },
    });
  };
  const individualTransactionApi = ({ offerId, propertyId, sourceAxios, delegateSide, delegateOwnerId,userAuthKey }) => {
  return axios.get(`${apiURL}/getIndividualTransaction`, {
      cancelToken: sourceAxios?.token,
      params: {
        ...(offerId ? { offerId } : propertyId ? { propertyId } : {}),
        ...(delegateSide ? { delegateSide } : {}),
        ...(delegateOwnerId ? { delegateOwnerId } : {}),
      },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userAuthKey}`,
      },
    });
  };


const deletePropertyContractDocsRequest = ({ payloadData }) => {
  let documentId = payloadData?.documentId
  let params = {
    ...(payloadData?.offerId && { offerId: payloadData.offerId }),
    ...(payloadData?.isToggleFrom && { isToggleFrom: payloadData.isToggleFrom }),
  };

  return axios.delete(`${apiURL}/RTD/deleteForm/${documentId}`, {
    params,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${payloadData?.token}`,
    },
  });
};
export const transactionLaneDataApi = {
  sendEmailToClientRequest,
  transactionLaneApi,
  updateDocumentApi,
  getNestedDoraDocuments,
  getemailtemplateRequest,
  urlShorthandApi,
  updateEmailRequest,
  updateTransactionEmailRequest,
  addEmailRequest,
  getSharedDocsRequest,
  individualTransactionApi,
  deletePropertyContractDocsRequest
};
