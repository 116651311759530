/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import RtdDesign from "./component/RtdDesign";
import {  useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "./state/actions";
import { Loading } from "../Common/components/Loading";
import DoraDocument from "./component/DoraDocument";
import NavbarForRtd from "./component/NavbarForRtd";
import styles from "./style/TransactionLane.module.css";
import axios from "axios";
import { getOfferIdFromUrl } from "../Common/utils/universalFuntions";


const TransactionLane = () => {

  const dispatch = useDispatch();
  const [currentlySelectedData, setCurrentlySelectedData] = useState();
  const [loading, setLoading] = useState(true);
  const offerId = getOfferIdFromUrl();

  const { offerTableData, offerTableAuthKey, URLofferTableJSON } = useSelector((state) => state.offerTable);

  useEffect(() => {
    let selectd = offerTableData?.filter((el) => el?.offerId === offerId);
    setCurrentlySelectedData(selectd?.[0]);
  }, [offerTableData]);

  const { triggerIndividualTransactionFetch } = useSelector((state) => state.rtdTransactionLane);

  useEffect(() => {
    let unmounted = { value: false };
    let sourceAxios = axios.CancelToken.source();
    if (offerId && offerTableAuthKey) {
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId, setLoading, unmounted, sourceAxios, userAuthKey: offerTableAuthKey }));
    }
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    };
  }, [offerTableAuthKey, triggerIndividualTransactionFetch]);


  useEffect(() => {
    if(offerId && offerTableAuthKey){
    dispatch(
      transactionLaneDataHandle.getTransactionLaneMethod(
        offerId,
        "SELLER",
        offerTableAuthKey
      )
    );

    let data = {
      listType: "SELLER",
      offerId,
      offerTableAuthKey
    };
    dispatch(transactionLaneDataHandle.getDoraDocumentsData(data, offerTableAuthKey));
    dispatch(transactionLaneDataHandle.getEmail_Template(offerTableAuthKey));
  }
  }, [offerTableAuthKey])



  return (
    <>
      {
        false ? (
          <Loading />
        ) : (
            <div className={styles.mainContainer}>
              <div className={styles.preRTDNav}>
                <NavbarForRtd currentlySelectedData={currentlySelectedData} />
              </div>
              { loading 
                ? (
                  <Loading />
                ) : (
                  <div className={styles.outerSectionScroll}>
                    <div className={styles.preRTD}>
                      <div className={styles.RTDSection}>
                        <RtdDesign
                          tags={"SELLER"}
                          client={"SELLER"}
                          selectedData={currentlySelectedData}
                          userAuthKey={offerTableAuthKey}
                          URLofferTableJSON={URLofferTableJSON}
                          formattedId={offerId}
                        />
                      </div>
                      <div className={styles.DoraDocumentSection}>
                        <DoraDocument
                          selectedData={currentlySelectedData}
                          seller="SELLER"
                          userAuthKey={offerTableAuthKey}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
        )}
    </>
  );
};

export { TransactionLane };
