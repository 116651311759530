/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { Menu, Dropdown, Modal, Spin, message } from "antd";
import { Alert } from "antd";
import { SaveOutlined, RetweetOutlined, CheckCircleOutlined, CopyOutlined, ExportOutlined, MailOutlined, RedoOutlined, ExclamationCircleOutlined, FileTextOutlined, FormOutlined } from "@ant-design/icons";
import { HiArrowLeft } from "react-icons/hi2";
import "../styles/ContractNav.css";
import FormValidationModal from "./FormValidationModal";
import dotEnv from "dotenv";
import Logo from "../../Assets/Icons/newLogo.png";
import { useMediaQuery } from "react-responsive";
import { createOfferContractTypes, customerDocsContractTypes, noChangeCheckboxContractTypes } from "../../Common/commondata/commonData";
import CreateTemplateModal from "./CreateTemplateModal";
import { handleClose } from "../../Common/commonFunctions/handleClose";
import { SignableAndReadOnly } from "../../Common/components/SignableAndReadOnly";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";

dotEnv.config();
const { confirm } = Modal;

function ContractNav() {
  const dispatch = useDispatch();
  const history = useHistory();

  const query = new URLSearchParams(window.location?.search);
  const queryOrigin = query?.get("sameOrigin");
  const tabId = query?.get("tabId");
  const clientId = query?.get("reqId");
  const destination = query?.get("dest");
  const email = query?.get("email");
  const omdUrlId = query?.get("omdUrlId");
  let urlId = useParams()?.id;
  const [modalState, setModalState] = useState({
    createTemplateModal: false,
    validateModal: false,
    isMenuVisible: false,
    loadTemplates: false,
  });
  const [isEmailModalVisible, setIsEmailModalVisible] = useState({
    isEmailModalOpen: false,
    sendMultipleDoc: true,
  });
  const isMob = useMediaQuery({ maxWidth: 500 });

  const { contractAuthKey, rootDocData, contractData } = useSelector((state) => state.createOffer);
  const { addClausesData, addDocumentsData, autoSaveContractStaus, transparentDivData } = useSelector((state) => state.contractStore);
  const { getDoraDocumentsData, getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  let openFor, contractType, invalidated, RTDInitiator, showSeller, showBuyer, offerId, documentId, propertyId, signatureInfo, signable, builtForSection, createOrEditTemplate, sellerSignStatus, sellerAgentSignStatus, checkForDisable;

  if (rootDocData) {
    ({ openFor, contractType, invalidated, RTDInitiator, showSeller, showBuyer, offerId, documentId, propertyId, signatureInfo, signable, builtForSection, createOrEditTemplate, sellerSignStatus, sellerAgentSignStatus, checkForDisable } = rootDocData);
  }

  const canCounterOfferBeCreated = createOfferContractTypes.includes(contractType) && openFor === "SELLER_AGENT" && builtForSection === "DOCUMENT" && offerId && documentId && !(sellerSignStatus === "SUBMITTED") && !checkForDisable?.disabled;

  useEffect(() => {
    if (canCounterOfferBeCreated && offerId && contractAuthKey) {
      let unmounted = { value: false };
      let sourceAxios = axios.CancelToken.source();
      let data = {
        listType: "SELLER",
        offerId,
        contractAuthKey,
      };
      dispatch(transactionLaneDataHandle.getDoraDocumentsData(data, contractAuthKey));
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId, propertyId, unmounted, sourceAxios, userAuthKey: contractAuthKey }));
    }
  }, [canCounterOfferBeCreated, offerId, contractAuthKey]);

  const handlePrint = async () => {
    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    if (rootDocData) {
      dispatch(contractStoreHandle.downloadPdfFun());
    }
  };

  let loclData = JSON.parse(localStorage.getItem(urlId) || "{}") || {};
  if (loclData) {
    if (contractType === "DWR" || contractType === "BDS" || contractType === "BDB" || contractType === "FRASCONA_BDB") {
    } else {
      if (addClausesData) loclData["addClauseData"] = addClausesData;
      if (addDocumentsData) loclData["addDocumentData"] = addDocumentsData;
      localStorage.setItem(urlId, JSON.stringify(loclData));
    }
  }

  const goBack = () => {
    handleClose({ source: "ContractNav", builtForSection, urlId, tabId, openFor, contractType, documentId, offerId, queryOrigin, clientId, propertyId, rootDocData, signatureInfo, history, destination, email, omdUrlId });
  };

  const handleBack = () => {
    if (contractType === "NET_SHEET" && builtForSection === "DOCUMENT" && ["SELLER_AGENT", "TITLE_COMPANY"].includes(openFor)) {
      netSheetConfirmationModal({ from: "back" });
    } else {
      goBack();
    }
  };

  const netSheetConfirmationModal = ({ from }) => {
    confirm({
      title: `Would you like to apply these changes to all the Net Sheets for this listing?`,
      content: "",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (from === "save") {
          dispatch(contractStoreHandle.autoSaveContract({ currentUrlId: urlId, contractAuthKey, netSheetUpdateType: "NET_SHEET_DATA_TRANSFER" }));
        } else if (from === "back") {
          dispatch(contractStoreHandle.autoSaveContract({ currentUrlId: urlId, contractAuthKey, goBack, netSheetUpdateType: "NET_SHEET_DATA_TRANSFER" }));
        }
      },
      onCancel() {
        message.success("Changes have been applied to this document only.");
        if (from === "back") {
          goBack();
        }
      },
    });
  };

  const handleSaveButtonClick = () => {
    if (urlId) {
      if (rootDocData?.contractType === "NET_SHEET" && builtForSection === "DOCUMENT" && ["SELLER_AGENT", "TITLE_COMPANY"].includes(openFor)) {
        netSheetConfirmationModal({ from: "save" });
      } else {
        dispatch(contractStoreHandle.autoSaveContract({ currentUrlId: urlId, contractAuthKey }));
      }
    }
  };

  const handleSaveAsTemplate = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: true, isMenuVisible: false }));
  };

  const handleCopyToDraft = () => {
    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    confirm({
      title: `This will create a copy of the existing offer with all of the existing values except the signatures and it will be available as a draft offer.`,
      content: "Are you sure you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(contractStoreHandle.copyAsDraftOfferFun({ offerId, documentId, openFor }));
      },
      onCancel() {},
    });
  };
  const handleLoadTemplate = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: true, isMenuVisible: false, loadTemplates: true }));
    let query = {
      contractType,
    };
    dispatch(contractStoreHandle.getTemplatesData({ query }));
  };
  const handleFillEmptyValuesWithNA = () => {
    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    const noChangeCheckboxExist = [...noChangeCheckboxContractTypes, "ET"].includes(contractType);
    confirm({
      title: `This will fill all blank input fields in this document with N/A.`,
      content: `Are you sure you want to proceed?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        try {
          const localData = JSON.parse(localStorage.getItem(urlId) || "{}") || {};
          const changedData = {};
          let hasChanges = false;
          document.querySelectorAll("input,textarea").forEach((element) => {
            // Skiping irrelevant elements
            if (["date", "time", "checkbox", "radio", "number"].includes(element.type) || !element.name || ["BuyerList", "SellerList", "FullAddress", "SellerBrokersFirmList", "BuyerBrokersFirmList", "SellerBrokersList", "BuyerBrokersList"].includes(element.name) || (element.hasAttribute("data-linked-value") && localData[element.getAttribute("data-linked-value")]) || (noChangeCheckboxExist && (element.classList.contains("relativeDateInput") || element.classList.contains("relativeTimeInput")))) {
              return;
            }
            // Filling blank inputs and textareas with N/A
            if (!element.value) {
              element.value = "N/A";
              localData[element.name] = "N/A";
              changedData[element.name] = "N/A";
              hasChanges = true;
            }
          });

          if (hasChanges) {
            localStorage.setItem(urlId, JSON.stringify(localData));
            dispatch(
              contractStoreHandle.autoSaveContract({
                currentUrlId: urlId,
                contractAuthKey,
                valueObj: changedData,
              })
            );
            message.success("Blank input fields have been successfully filled with N/A.");
          } else {
            message.info("No blank fields were found that could be updated with N/A automatically. If you believe any field was missed, please enter N/A manually in that input field.");
          }
        } catch (error) {
          message.error("Currently, we are unable to fill the blank input fields with N/A. Please try again later. If the issue persists, please contact RE-Sure Support.");
        }
      },
      onCancel() {},
    });
  };

  const handleCreateCounterOffer = () => {
    if (!Array.isArray(getDoraDocumentsData) || getDoraDocumentsData.length === 0 || !getIndividualTransactionData?.currentAgentId) {
      return;
    }

    setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
    const doraDocItem = contractType === "CBS_MH" ? getDoraDocumentsData.find((el) => el.contractType === "MH_CO") : getDoraDocumentsData.find((el) => el.contractType === "CO");
    confirm({
      title: `Are you sure you want to create a Counter Offer for this Offer?`,
      content: ``,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        try {
          const localData = JSON.parse(localStorage.getItem(urlId) || "{}") || {};
          const currentAgentDetails = getIndividualTransactionData.currentAgentId;
          const officeDetails = {
            officeLogo: currentAgentDetails?.brokerageData?.logo || "",
            name: currentAgentDetails?.brokerageData?.name?.trim() || "",
            address: currentAgentDetails?.brokerageData?.address || "",
            agentId: currentAgentDetails?._id,
          };

          let buildData = {
            builtForId: rootDocData?.builtForId,
            builtForSection: "DOCUMENT",
            signable: true,
            openFor: "SELLER_AGENT",
            contractId: doraDocItem.contractId,
            contractType: doraDocItem.contractType,
            offerId,
            propertyId,
            token: contractAuthKey,
            RTDInitiator: "SELLER",
            urlData: {
              ...(localData || {}),
              ...(doraDocItem?.prefillValues || {}),
              officeDetails,
            },
          };
          dispatch(transactionLaneDataHandle.doraDocsUrlShorthandApiCalling({ buildData, history, source: "CREATE_COUNTER_OFFER" }));
        } catch (error) {
          message.error("Currently, we are unable to create Counter Offer. Please try again later. If the issue persists, please contact RE-Sure Support.");
        }
      },
      onCancel() {},
    });
  };
  const handleMenu = (event) => {
    switch (event.key) {
      case "expand":
        // handleExpand();
        break;
      case "export": {
        handlePrint();
        break;
      }
      case "saveAsTemplate": {
        handleSaveAsTemplate();
        break;
      }
      case "loadTemplates": {
        handleLoadTemplate();
        break;
      }
      case "fillEmptyValuesWithNA": {
        handleFillEmptyValuesWithNA();
        break;
      }
      case "copyToDraft": {
        handleCopyToDraft();
        break;
      }
      case "createCounterOffer": {
        handleCreateCounterOffer();
        break;
      }
      case "inputOnly":
        // handleExpand();
        break;
      default:
        break;
    }
  };
  // dropdown menu options
  const menu = () => {
    let atleastOneBuyerSigned = false,
      atleastOneSellerSigned = false;
    signatureInfo?.forEach((element) => {
      if (element.roleType === "BUYER" || element.roleType === "BUYER_AGENT") {
        atleastOneBuyerSigned = true;
      } else {
        atleastOneSellerSigned = true;
      }
    });

    const handleResetSignature = () => {
      setModalState((prevState) => ({ ...prevState, isMenuVisible: false }));
      confirm({
        title: `Are you sure you want to reset all the signatures?`,
        content: "Note: Changes made cannot be reverted back again",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(contractStoreHandle.resetSignatures(documentId, openFor, contractAuthKey));
        },
        onCancel() {},
      });
    };

    return (
      <>
        {modalState?.isMenuVisible ? (
          <Menu onClick={handleMenu} style={{ background: "#124e8f", zIndex: 12 }} className="no-print">
            {documentId && (
              <Menu.Item key="export" style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }} icon={<ExportOutlined style={{ color: "#fff" }} />}>
                Export PDF
              </Menu.Item>
            )}
            {builtForSection === "DOCUMENT" && documentId && ((RTDInitiator === "BUYER" && openFor === "BUYER_AGENT") || (RTDInitiator === "SELLER" && openFor === "SELLER_AGENT")) ? (
              <Menu.Item
                key="saveAsTemplate"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <SaveOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Save As Template
              </Menu.Item>
            ) : null}
            {builtForSection === "DOCUMENT" && !signatureInfo?.length && ((RTDInitiator === "BUYER" && openFor === "BUYER_AGENT") || (RTDInitiator === "SELLER" && openFor === "SELLER_AGENT")) ? (
              <Menu.Item
                key="loadTemplates"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <FileTextOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Load Template
              </Menu.Item>
            ) : null}
            {builtForSection === "DOCUMENT" && !signatureInfo?.length && contractType !== "TR" && ((RTDInitiator === "BUYER" && openFor === "BUYER_AGENT") || (RTDInitiator === "SELLER" && openFor === "SELLER_AGENT")) ? (
              <Menu.Item
                key="fillEmptyValuesWithNA"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <FormOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Fill blank values with N/A
              </Menu.Item>
            ) : null}
            {createOfferContractTypes.includes(contractType) && openFor === "BUYER_AGENT" && builtForSection === "DOCUMENT" && offerId && documentId ? (
              <Menu.Item
                key="copyToDraft"
                style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }}
                icon={
                  <CopyOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Copy To Draft
              </Menu.Item>
            ) : null}
            {canCounterOfferBeCreated ? (
              <Menu.Item
                key="createCounterOffer"
                style={{
                  color: "#fff",
                  backgroundColor: "#124e8f",
                  fontSize: isMob ? "10px" : "small",
                }}
                icon={
                  <RetweetOutlined
                    style={{
                      color: "#fff",
                      fontSize: "larger",
                      fontWeight: "bolder",
                    }}
                  />
                }
              >
                Create Counter Offer
              </Menu.Item>
            ) : null}

            {!["ERTB", "DWR", "BDB", "BDS", "LCAE", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT", "FRASCONA_BDB", "WFD"].includes(contractType) && ((openFor === "BUYER_AGENT" && RTDInitiator === "BUYER" && atleastOneBuyerSigned && !atleastOneSellerSigned && !showSeller) || (openFor === "SELLER_AGENT" && RTDInitiator === "SELLER" && !atleastOneBuyerSigned && atleastOneSellerSigned && !showBuyer && !["ERTS", "FRASCONA_ERTS"].includes(contractType)) || (openFor === "SELLER_AGENT" && RTDInitiator === "SELLER" && ["ERTS", "FRASCONA_ERTS"].includes(contractType) && atleastOneSellerSigned && !(sellerSignStatus === "SUBMITTED" && sellerAgentSignStatus === "SUBMITTED"))) && documentId && contractType !== "TR" && contractType !== "TO" && !checkForDisable?.disabled ? (
              <Menu.Item onClick={handleResetSignature} key="resetSignature" style={{ color: "#fff", backgroundColor: "#124e8f", fontSize: isMob ? "10px" : "small" }} icon={<RedoOutlined style={{ color: "#fff" }} />}>
                Reset Signatures
              </Menu.Item>
            ) : null}
          </Menu>
        ) : null}
      </>
    );
  };

  // when user clicks on the validate
  const handleValidate = () => {
    setModalState((prevState) => ({ ...prevState, validateModal: true }));
    dispatch(contractStoreHandle.validateForm(urlId, contractAuthKey));
  };

  let localData = JSON.parse(localStorage.getItem(urlId)) || {};
  const handleEmailIconClick = () => {
    let unmounted = { value: false };
    let sourceAxios = axios.CancelToken.source();
    if (!customerDocsContractTypes.includes(contractType) && (offerId || propertyId) && contractAuthKey) {
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId, propertyId, unmounted, sourceAxios, userAuthKey: contractAuthKey }));
    }
    if (!isEmailModalVisible.isEmailModalOpen) {
      setIsEmailModalVisible({
        ...isEmailModalVisible,
        isEmailModalOpen: true,
        sendMultipleDoc: true,
      });
    }
  };

  return (
    <>
      {localData && invalidated ? (
        <div style={{ position: "sticky", top: isMob ? "55px" : "70px", display: "flex", justifyContent: "center" }}>
          <Alert style={{ fontSize: isMob ? 10 : 16 }} showIcon message={`A more recent version of this document exists. Please see the current version in the Transaction Dashboard.`} type="error" />
        </div>
      ) : null}
      <div
        className="no-print"
        theme={"light"}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: "18px",
          zIndex: 10,
          width: "100%",
          minWidth: "-webkit-fill-available",
          background: "#124e8f",
          color: "#fff",
          border: "none",
          overflowX: "auto",
        }}
      >
        {isEmailModalVisible?.isEmailModalOpen && <SignableAndReadOnly isEmailModalVisible={isEmailModalVisible} setIsEmailModalVisible={setIsEmailModalVisible} userAuthKey={contractAuthKey} source="CONTRACT_NAV" rootDocData={rootDocData} role={rootDocData?.openFor === "BUYER_AGENT" ? "BUYER" : "SELLER"} offerId={rootDocData?.offerId} />}

        {/* Create Template Modal */}
        {modalState?.createTemplateModal && <CreateTemplateModal modalState={modalState} setModalState={setModalState} urlId={urlId} contractAuthKey={contractAuthKey} documentId={documentId} contractData={contractData} />}

        {/* ValidationModal */}
        {modalState?.validateModal && <FormValidationModal modalState={modalState} setModalState={setModalState} />}

        {(!documentId && autoSaveContractStaus) || transparentDivData?.loadingStatus ? (
          <div style={{ position: "fixed", zIndex: "1000", width: "100%", height: "100vh", backgroundColor: "black", opacity: "0.7", display: "flex", gap: "20px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Spin size="large" />
            {transparentDivData?.loadingStatus ? transparentDivData.message || "" : "Saving Contract..."}
          </div>
        ) : null}
        <div className={"navOptions"}>
          {builtForSection === "READ_ONLY_DOCUMENT" ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px" }}>
              <img style={{ width: "100px", height: "auto" }} src={Logo} alt="Logo" />
            </div>
          ) : (
            ""
          )}
          {builtForSection !== "READ_ONLY_DOCUMENT" ? (
            <div
              onClick={() => handleBack()}
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div>
                <HiArrowLeft
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <div>Back</div>
            </div>
          ) : (
            <div></div>
          )}
          {contractType !== "TR" &&  !["READ_ONLY_DOCUMENT", "TEMPLATE", "DOCUMENT_SET"].includes(builtForSection) && contractData?.backendValidationObject?.length ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px 0 10px 0",
                cursor: "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
              onClick={handleValidate}
            >
              <div>
                <CheckCircleOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <div>Validate</div>
            </div>
          ) : null}

          {builtForSection !== "READ_ONLY_DOCUMENT" || openFor === "TITLE_COMPANY" || builtForSection === "READ_ONLY_DOCUMENT" ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px 0 10px 0",
                color: "#fff",
                cursor: "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
            >
              <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={(visible) => setModalState((prevState) => ({ ...prevState, isMenuVisible: visible }))}>
                <ExportOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </Dropdown>
              <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={(visible) => setModalState((prevState) => ({ ...prevState, isMenuVisible: visible }))}>
                <div>Option</div>
              </Dropdown>
            </div>
          ) : (
            <div></div>
          )}

          {(builtForSection !== "READ_ONLY_DOCUMENT" || (openFor === "TITLE_COMPANY" && contractType === "CI")) && signable === true ? (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: autoSaveContractStaus ? "context-menu" : "pointer",
                pointerEvents: invalidated ? "none" : "auto",
              }}
            >
              {builtForSection === "TEMPLATE" && createOrEditTemplate === false ? (
                <></>
              ) : (
                <>
                  <div style={{ position: "relative" }}>
                    {autoSaveContractStaus ? (
                      <Spin size={isMob ? "" : "large"} className="custom-spinner" style={{ position: "absolute", fontSize: "20px", top: isMob ? 0 : 5, right: isMob ? "-10px" : "-15px" }} />
                    ) : (
                      <SaveOutlined
                        style={{
                          color: "#fff",
                          fontSize: "larger",
                          fontWeight: "bolder",
                        }}
                        {...(builtForSection !== "READ_ONLY_DOCUMENT" && !checkForDisable?.disabled ? { onClick: () => handleSaveButtonClick() } : {})}
                      />
                    )}
                  </div>
                  {autoSaveContractStaus ? null : <div {...(builtForSection !== "READ_ONLY_DOCUMENT" && !checkForDisable?.disabled ? { onClick: () => handleSaveButtonClick() } : {})}>Save</div>}
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {builtForSection === "DOCUMENT" && documentId && (openFor === "BUYER_AGENT" || openFor === "SELLER_AGENT") ? (
            <div
              onClick={handleEmailIconClick}
              style={{
                display: "grid",
                gridTemplateRows: "0.3fr 1fr",
                justifyContent: "center",
                textAlignLast: "center",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div>
                <MailOutlined
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    fontWeight: "bolder",
                  }}
                />
              </div>
              <div>Send Email</div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export { ContractNav };
