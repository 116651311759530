/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { memo, useCallback, useState } from "react";
import { Table, Typography, Row, Col, Space, Button, Checkbox, Tooltip, Dropdown, Menu, message, Modal } from "antd";
import { CheckCircleOutlined, PlusCircleFilled, CloseCircleOutlined, MoreOutlined, CaretUpOutlined,CaretDownOutlined,StopOutlined, LogoutOutlined, EyeInvisibleOutlined, EyeOutlined, SolutionOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { CustomInputField } from "./components/CustomInputField";
import { offerTableHandle } from "./state/actions";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { convertDate, disabledTransactionText, doubleDigitNumber, formatPrice, loanTypeData } from "../Common/commondata/commonData";
import { Loading } from "../Common/components/Loading";
import { SomeThingWentWrong } from "../Common/components/SomeThingWentWrong";
import styles from "./styles/OfferTable.module.css";
import "../CreateOffer/styles/RenderJsx.css";
import { useRef } from "react";
import { IoSendSharp } from "react-icons/io5";
import { Expandable } from "./components/Expandable";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { encryptStr } from "../Common/utils/ExtraFunctions";
import { convertTime } from "../Common/commondata/commonData.js";
import AddCriteria from "./components/AddCriteria";
import { useEffect } from "react";
import { detectMobile, formatArrayContents, parseValue } from "../Common/utils/universalFuntions.js";
import { SignableAndReadOnly } from "../Common/components/SignableAndReadOnly.jsx";
import moment from "moment";
import { commonDataHandle } from "../Common/state/actions.js";
import { getPropertyImages } from "./utils/helper.js";
import { transactionLaneDataHandle } from "../Rtd/state/actions.js";
const { Text, Title } = Typography;
const { Content } = Layout;

const OfferTableRender= memo(({ showhidden, sort, order,setOrder,setSort,setShowhidden,setisMenuopen})=> {

  const tableRef = useRef(0);
  const dispatch = useDispatch();
  // For sending docs
  const selectedD = useRef(null);

  const { id } = useParams();
  const [isEmailModalVisible, setIsEmailModalVisible] = useState({
    isEmailModalOpen: false,
    sendMultipleDoc: false,
  });
  const [addCriteriaModalOpen, setAddCriteriaModalOpen] = useState(false);
  const { offerTableCustomLoading, offerTableErrorData, offerTablePropertyId, OTDefaultKeysArray, offerTableData, offerTableloading, URLofferTableJSON, offerTableAuthKey, isAccpetedOfferPresent, checkForDisable, currentSellerAgentData } = useSelector((state) => state.offerTable);
  const history = useHistory();
  const search = useLocation()?.search;
  const query = new URLSearchParams(search);
  const urlOfferId = query?.get("offer");
  const myElementRef = useRef(null);

  const [dropdownKeys, setDropdownKeys] = useState({}); 

  // Handling dropdown visibility by changing its key
  const resetDropdown = (offerId) => {
    if (!offerId) return;
    setDropdownKeys((prevKeys) => ({
      ...prevKeys,
      [offerId]: (Number(prevKeys[offerId]) || 0) + 1,
    }));
  };

  const handleShowModal = (item) => {
    resetDropdown(item?.offerId);
    selectedD.current = item;
    setIsEmailModalVisible({...isEmailModalVisible,isEmailModalOpen:true});
  };

  let save = (index, key, ele) => {
    let LoanTypeTempConversion = {
      Conventional: 133,
      FHA: "Conventional",
      VA: "FHA",
      Bond: "VA",
    };
    let loanValue = "";
    let dd = document.getElementById(index);
    loanValue = dd.value;
    let arraycontainsloantype = loanTypeData.indexOf(loanValue) > -1;
    if (arraycontainsloantype) {
      loanValue = LoanTypeTempConversion[dd.value];
    }
    if (loanValue?.length > 0) {
      ele.transactionData[[key]] = loanValue;
      let ordernew = order ? order : "ASC";
      let sortnew = sort ? sort : "PRICE";
      dispatch(offerTableHandle.inputFill(offerTableAuthKey, offerTablePropertyId, ele.offerId, ele.transactionData, sortnew, ordernew, ele));
    }
  };

  let getOfferNumber = (number) => {
    if ((number + "").length > 1) {
      return number;
    } else {
      return doubleDigitNumber[number];
    }
  };

  // <----logic for hide offers----->
  let add = 0;
  const handlefilter = (item, isHidden) => {
    if (add === mergedColumns.length - 2 && !isHidden) {
      message.error("Unable to hide offer. Currently, there is only last offer remaining to hide.");
      return;
    }

    let ordernew = order ? order : "ASC";
    let sortnew = sort ? sort : "PRICE";
    dispatch(offerTableHandle.hiddenOffer(offerTableAuthKey, offerTablePropertyId, item, !isHidden, sortnew, ordernew));
  };

  const handleOfferStatusChange = useCallback(
    ({ ele, isTrue, status, statusChangeTo, statusChangeFrom }) => {
      const ordernew = order || "ASC";
      const sortnew = sort || "PRICE";
      if (statusChangeTo && statusChangeFrom) {
        // Termination logic
        resetDropdown(ele?.offerId);
        Modal.confirm({
          title: `Are you sure you want to terminate or discontinue working on this offer?`,
          content: "Note: Please ensure that either the 'Notice to Terminate' or the 'Extension or Termination of Contract' document has been submitted.",
          okText: "Yes",
          cancelText: "No",
          icon: <ExclamationCircleOutlined />,
          onOk() {
            dispatch(offerTableHandle.makeOfferStatusChange({ offerTableAuthKey, offerTablePropertyId, offerId: ele?.offerId, sort: sortnew, order: ordernew, statusChangeTo, statusChangeFrom }));
          },
          onCancel() {},
        });
      } else {
        // General status change
        dispatch(offerTableHandle.makeOfferStatusChange({ offerTableAuthKey, offerTablePropertyId, offerId: ele?.offerId, documentId: ele?.CBSRId, isTrue, sort: sortnew, order: ordernew, status }));
      }
    },
    [offerTableAuthKey, offerTablePropertyId]
  );
  

const handleSortTable = (sorttable, ordertable) => {
  setOrder(ordertable);
  setSort(sorttable);
  dispatch(offerTableHandle.getOfferTable(offerTableAuthKey, offerTablePropertyId, sorttable, ordertable));
};
const [isMobiletrue,setIsmobiletrue]=useState(false)
useEffect(() => {
  const fetchData = async () => {
    const isMobile= detectMobile()
    setIsmobiletrue(isMobile)
  };

  fetchData(); // Call the function when the component mounts

}, []);

const handlegetSignData = useCallback(() => {
    dispatch(commonDataHandle.getEmailSignData(offerTableAuthKey));
  }, []);

useEffect(() => {
    if(isEmailModalVisible?.isEmailModalOpen)
    handlegetSignData()
}, [isEmailModalVisible?.isEmailModalOpen])

let sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;

// Open Net Sheet-
  const handleOpenNetSheet = (item) => {
    const transactionData = item?.transactionData || {}
    const officeDetails = {
      officeLogo: currentSellerAgentData?.brokerageData?.brokerageLogo || currentSellerAgentData?.brokerageData?.brokerageId.logo || '',
      name: currentSellerAgentData?.brokerageData?.brokerageName?.trim() || '',
      address: currentSellerAgentData?.brokerageData?.brokerageAddress || '',
      agentId: currentSellerAgentData?._id
    };
  const buildData = {
    builtForId: URLofferTableJSON?.builtForId || currentSellerAgentData?._id,
    key: URLofferTableJSON?.builtForId || currentSellerAgentData?._id,
    builtForSection: "DOCUMENT",
    signable: !sellerOpenOfferTable,
    openFor: sellerOpenOfferTable ? "SELLER" : "SELLER_AGENT",
    contractType: "NET_SHEET",
    contractId: "67600c61c97ed74c66d1a94c",
    propertyId: URLofferTableJSON?.propertyId,
    ...(URLofferTableJSON?.thirdpartyId && { thirdpartyId: URLofferTableJSON?.thirdpartyId }),
    ...(item?.netSheetId && { documentId: item.netSheetId }),
    offerId: item?.offerId,
    token: offerTableAuthKey,
    ...(!item?.netSheetId && {
      buyerAgentIds: item?.buyerAgentIds,
      sellerAgentIds: item?.sellerAgentIds,
      RTDInitiator: "SELLER",
      urlData: {
        PurchasePrice: transactionData['PurchasePrice'] || "",
        TotalEstimateNetProceeds: transactionData['PurchasePrice'] || "",
        Sellers: transactionData['Sellers'] || [],
        Buyers: transactionData['Buyers'] || [],
        SellerAgents: transactionData['SellerAgents'],
        BuyerAgents: transactionData['BuyerAgents'],
        FullAddress: transactionData['FullAddress'],
        officeDetails,
      },
    }),
  };
   dispatch(transactionLaneDataHandle.doraDocsUrlShorthandApiCalling({buildData, history}));
};


useEffect(() => {
  // Access the DOM node using current property of the ref

  const myElement = myElementRef.current;
 const isMobileInLandscape = window.matchMedia("(max-width: 1080px) and (orientation: landscape)").matches ;
if (myElement && !isMobileInLandscape ) {
      if((myElement.textContent==="Down Payment" && isMobiletrue) || (myElement.textContent==="Loan Type" && isMobiletrue)){
        const boundingBox = myElement.getBoundingClientRect();
        const heightUpToText = boundingBox.top + window.scrollY; // Adjusted to include the current scroll position
    window.scrollTo({
          top: heightUpToText-550,
          behavior: "auto", // You can try "auto" or "instant" if "smooth" is not working
        });
        }else{
          myElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    }
}, []);
const menuorder={
  top:"200px",
  left:"400px",
  position:"fixed",
 };
 const menuorder1={
  top:"250px",
  left:"200px",
  position:"fixed",
 };

 const menuorder3={
  top:"200px",
  left:"500px",
  position:"fixed",
 };const menuorder4={
  top:"200px",
  left:"500px",
  position:"fixed",
  };

  const handleAcceptViaResureSign = (ele) => {
    dispatch(offerTableHandle.acceptOfferViaResureSignFun({offerTableAuthKey, offerTablePropertyId,documentId:ele?.CBSRId}));
}
  // Implementing send email and send password
  const MenuItem = (item) => {
    return (
      <Menu size="small" style={isMobiletrue && window.innerWidth < window.innerHeight ? menuorder1 : isMobiletrue && window.innerWidth > window.innerHeight && mergedColumns.length === 2 ? menuorder : isMobiletrue && window.innerWidth > window.innerHeight && mergedColumns.length === 3 ? menuorder3 : isMobiletrue && window.innerWidth > window.innerHeight && mergedColumns.length === 4 ? menuorder4 : isMobiletrue && window.innerWidth > window.innerHeight ? menuorder4 : {}}>
        <>
          <Menu.Item
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
            }}
            icon={
              <IoSendSharp
                style={{
                  color: "#0036F4",
                  fontSize: "20px",
                }}
              />
            }
            key="SHARE_INDIVIDUAL_OFFER"
            onClick={() => handleShowModal(item?.item)}
          >
            Send Offer
          </Menu.Item>
        </>

        {(item.item?.data?.offerStatus === "INPROGRESS" || item?.item?.data?.offerStatus === "HOLD") && (
          <>
            <Menu.Item
              key="option"
              icon={
                item?.item?.data?.offerStatus === "INPROGRESS" ? (
                  <StopOutlined
                    style={{
                      color: "#0036F4",
                      fontSize: "20px",
                    }}
                  />
                ) : item?.item?.data?.offerStatus === "HOLD" ? (
                  <LogoutOutlined
                    style={{
                      color: "#0036F4",
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  ""
                )
              }
              {...(checkForDisable?.disabled
                ? { onClick: disabledTransactionText }
                : {
                    onClick: () => {
                      if (item?.item?.data?.offerStatus === "INPROGRESS") {
                        handleOfferStatusChange({ ele: item?.item, isTrue: true, status: "HOLD" });
                      } else if (item?.item?.data?.offerStatus === "HOLD") {
                        handleOfferStatusChange({ ele: item?.item, isTrue: false, status: "HOLD" });
                      }
                    },
                  })}
            >
              <text
                style={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {" "}
                {item?.item?.data?.offerStatus === "INPROGRESS" ? "Hold offer" : item?.item?.data?.offerStatus === "HOLD" ? "Progress offer" : ""}
              </text>
            </Menu.Item>
          </>
        )}
        <Menu.Item
          style={{
            display: "flex",
            alignItems: "center",
          }}
          icon={
            !item?.markedAsHidden ? (
              <EyeInvisibleOutlined
                style={{
                  color: "#0036F4",
                  fontSize: "20px",
                }}
              />
            ) : (
              <EyeOutlined
                style={{
                  color: "#0036F4",
                  fontSize: "20px",
                }}
              />
            )
          }
          onClick={() => (mergedColumns.length > 2 ? handlefilter(item.item, item.item?.markedAsHidden ? true : false) : message.error("Unable to hide offer. Currently, there is only one offer available to hide."))}
        >
          {!item.item?.markedAsHidden ? (
            <text
              style={{
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              Hide Offer
            </text>
          ) : (
            <text
              style={{
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              Unhide Offer
            </text>
          )}
        </Menu.Item>
        {item?.item?.ispdfClone && (item?.item?.data?.offerStatus === "SUBMITTED" || item?.item?.data?.offerStatus === "INPROGRESS") && !isAccpetedOfferPresent ? (
          <>
            {" "}
            <Menu.Item
              style={{
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
              icon={
                <CheckCircleOutlined
                  style={{
                    color: "#71BB66",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                />
              }
              key="makeOfferAccepted"
              {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => handleOfferStatusChange({ ele: item?.item, isTrue: true, status: "ACCEPTED" }) })}
            >
              Mark Accepted
            </Menu.Item>
            <Menu.Item
              style={{
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
              icon={
                <CheckCircleOutlined
                  style={{
                    color: "#71BB66",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                />
              }
              key="acceptviaResureSign"
              {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => handleAcceptViaResureSign(item?.item) })}
            >
              Accept via RE-Sure Sign
            </Menu.Item>
          </>
        ) : null}
        {item?.item?.data?.offerStatus === "ACCEPTED" ? (
          <>
            {" "}
            <Menu.Item
              style={{
                color: "#000000",
                fontWeight: "500",
                fontSize: "16px",
              }}
              icon={
                <SolutionOutlined
                  style={{
                   color: "#2885DC",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                />
              }
              key="terminateOffer"
              onClick={(event) => handleOfferStatusChange({ ele: item?.item, statusChangeTo: "TERMINATED", statusChangeFrom: "ACCEPTED" })}
            >
              Terminate Offer
            </Menu.Item>
          </>
        ) : null}
      </Menu>
    );
  };
  const handleCriteriaClick = () => {
    setAddCriteriaModalOpen(true);
  };
  let columns = [];
  // Maintain original array
  let rowData = offerTableData;
  if (rowData && rowData.length > 0 && OTDefaultKeysArray && OTDefaultKeysArray.length > 0) {
    let firstColumnData = OTDefaultKeysArray;
    let firstColumn = {
      title: (
        <Space
         className={styles.criteria}
          size="middle"
        >
          Criteria
          <Tooltip title={"Add Criteria"} placement="top">
            <PlusCircleFilled
              style={{
                fontSize: "25px",
                fill: "#fff",
                color: "#fff",
                // marginLeft: "1.rem",
              }}
              {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: handleCriteriaClick})}
            />
          </Tooltip>
        </Space>
      ),
      key: uuidv4(),
      className: styles.firstColAlignment,
      fixed: "left",
      // width:  180,
      render: (e, i, t) => {

        return (
          firstColumnData[t]==="Price" && sort==="PRICE" ? <div id="firstCol" ref={myElementRef}  className={styles.firstColHeading} style={{ border: "4px solid orange",
         top: "0px",
         textAlign:"center",
         display:"grid",
          left: "0px",
          cursor:"pointer",
          right: "0px",
          bottom: "0px",
          position: "absolute",}}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }}    onClick={()=>handleSortTable(sort,order==="ASC"?"DEC":"ASC")}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>

               <CaretUpOutlined
               style={{
               marginTop:"5px",color:order==="ASC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px"}} onClick={()=>handleSortTable(sort,"ASC")} />
                <CaretDownOutlined
                style={{
               marginBottom:"5px",color:order==="DEC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px"}}  onClick={()=>handleSortTable(sort,"DEC")}/></div>
                  </div>
          </div>:
            ((firstColumnData[t] === "Net To Seller") && (sort === "NET_SHEET_AMOUNT")) ?
              <div id="firstCol" ref={myElementRef} className={styles.firstColHeading} style={{
                border: "4px solid orange",
                top: "0px",
                textAlign: "center",
                display: "grid",
                left: "0px",
                cursor: "pointer",
                right: "0px",
                bottom: "0px",
                position: "absolute",
              }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => handleSortTable(sort, order === "ASC" ? "DEC" : "ASC")}>
                  <div style={{ width: "90%", display: "flex", justifyContent: "center" }}>
                    {firstColumnData[t]}
                  </div>
                  <div style={{ display: "block", width: "10%", marginRight: "20px" }}>
                    <CaretUpOutlined style={{
                      marginTop: "5px", color: order === "ASC" ? "orange" : "hsl(209, 85%, 39%)", fontSize: "24px"
                    }} onClick={() => handleSortTable(sort, "ASC")} />
                    <CaretDownOutlined style={{
                      marginBottom: "5px", color: order === "DEC" ? "orange" : "hsl(209, 85%, 39%)", fontSize: "24px"
                    }} onClick={() => handleSortTable(sort, "DEC")} />
                  </div>
                </div>
              </div>
          :firstColumnData[t]==="Closing Date" && sort==="CLOSING_DATE" ?
          <div id="firstCol" ref={myElementRef}  className={styles.firstColHeading} style={{ border: "4px solid orange",
          cursor:"pointer",
         top: "0px",
         textAlign:"center",
         display:"grid",
          left: "0px",
          right: "0px",
          bottom: "0px",
          position: "absolute",}} onClick={()=>handleSortTable(sort,order==="ASC"?"DEC":"ASC")}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>
               <CaretUpOutlined
               style={{
                marginTop:"5px",color:order==="ASC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                }} onClick={()=>handleSortTable(sort,"ASC")} />
                <CaretDownOutlined
                style={{
                marginBottom:"5px",color:order==="DEC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                  }} onClick={()=>handleSortTable(sort,"DEC")}/></div>
                  </div>
          </div>:firstColumnData[t]==="Loan Type" && sort==="LOAN_TYPE" ? <div id="firstCol" ref={myElementRef}  className={styles.firstColHeading} style={{ border: "4px solid orange",
         top: "0px",
         textAlign:"center",
         display:"grid",
          left: "0px",
          right: "0px",
          bottom: "0px",
          cursor:"pointer",
          position: "absolute",}}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }} onClick={()=>handleSortTable(sort,order==="ASC"?"DEC":"ASC")}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>
               <CaretUpOutlined
               style={{
                marginTop:"5px",color:order==="ASC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                }}
                onClick={()=>handleSortTable(sort,"ASC")}  />
                <CaretDownOutlined
                style={{
              marginBottom:"5px",color:order==="DEC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                  }} onClick={()=>handleSortTable(sort,"DEC")}/></div>
                  </div>
          </div>: firstColumnData[t]==="Down Payment" && sort==="DOWN_PAYMENT_AMOUNT" ?
          <div id="firstCol" ref={myElementRef}  className={styles.firstColHeading} style={{ border: "4px solid orange",
          cursor:"pointer",
         top: "0px",
         textAlign:"center",
         display:"grid",
          left: "0px",
          right: "0px",
          bottom: "0px",
          position: "absolute",}} onClick={()=>handleSortTable(sort,order==="ASC"?"DEC":"ASC")}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>
               <CaretUpOutlined
               style={{
                marginTop:"5px",color:order==="ASC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
              }} onClick={()=>handleSortTable(sort,"ASC")} />
                <CaretDownOutlined
                style={{
                  marginBottom:"5px",color:order==="DEC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                  }} onClick={()=>handleSortTable(sort,"DEC")}/></div>
                  </div>
          </div>:firstColumnData[t]==="Date Submitted" && sort==="DATE_SUBMITTED" ?
          <div id="firstCol" ref={myElementRef} className={styles.firstColHeading} style={{ border: "4px solid orange",
         top: "0px",
         textAlign:"center",
         cursor:"pointer",
         display:"grid",
          left: "0px",
          right: "0px",
          bottom: "0px",
          position: "absolute",}}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }} onClick={()=>handleSortTable(sort,order==="ASC"?"DEC":"ASC")}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>
               <CaretUpOutlined
               style={{
             marginTop:"5px",color:order==="ASC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                }}
                 onClick={()=>handleSortTable(sort,"ASC")} />
                <CaretDownOutlined
                style={{
                 marginBottom:"5px",color:order==="DEC"?"orange":"hsl(209, 85%, 39%)",fontSize:"24px",
                  }}
                   onClick={()=>handleSortTable(sort,"DEC")}/></div>
                  </div>
          </div>:
          firstColumnData[t]==="Price" || firstColumnData[t]==="Net To Seller" ||firstColumnData[t]==="Closing Date"||firstColumnData[t]==="Loan Type"||
          firstColumnData[t]==="Date Submitted"||firstColumnData[t]==="Down Payment"?
          <div id="firstCol" className={styles.firstColHeading} style={{
         top: "0px",
         textAlign:"center",
         display:"grid",
          left: "0px",
          right: "0px",
          bottom: "0px",
          cursor:"pointer",
          position: "absolute",}} onClick={()=>
          handleSortTable(firstColumnData[t]==="Price"?"PRICE":
          firstColumnData[t]==="Net To Seller"?"NET_SHEET_AMOUNT":
          firstColumnData[t]==="Closing Date"?"CLOSING_DATE":
          firstColumnData[t]==="Loan Type"?"LOAN_TYPE":
          firstColumnData[t]==="Date Submitted"?"DATE_SUBMITTED":
          firstColumnData[t]==="Down Payment"?"DOWN_PAYMENT_AMOUNT":sort,"DEC")}>
          <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            }}><div
             style={{width:"90%",
             display:"flex",
             justifyContent:"center",
             }}>
             {firstColumnData[t]}</div>
             <div style={{
              display:"block",
              width:"10%",
              marginRight:"20px"
              }}>
               <CaretUpOutlined
               style={{
                marginTop:"5px",color:"hsl(209, 85%, 39%)",fontSize:"24px"}}
                 onClick={()=>handleSortTable(firstColumnData[t]==="Price"?"PRICE": (firstColumnData[t] === "Net To Seller") ? "NET_SHEET_AMOUNT" : firstColumnData[t]==="Closing Date"?"CLOSING_DATE":firstColumnData[t]==="Loan Type"?"LOAN_TYPE":firstColumnData[t]==="Date Submitted"?"DATE_SUBMITTED":firstColumnData[t]==="Down Payment"?"DOWN_PAYMENT_AMOUNT":sort,"ASC")}  />
                <CaretDownOutlined
                style={{
                  marginBottom:"5px",color:"hsl(209, 85%, 39%)",fontSize:"24px"}}
                  onClick={()=>handleSortTable(firstColumnData[t]==="Price"?"PRICE": (firstColumnData[t] === "Net To Seller") ? "NET_SHEET_AMOUNT" : firstColumnData[t]==="Closing Date"?"CLOSING_DATE":firstColumnData[t]==="Loan Type"?"LOAN_TYPE":firstColumnData[t]==="Date Submitted"?"DATE_SUBMITTED":firstColumnData[t]==="Down Payment"?"DOWN_PAYMENT_AMOUNT":sort,"DEC")} /></div>
                  </div>
          </div>:<div>{firstColumnData[t]}</div>
        );
      },
    };

    let heading = offerTableData.filter((el) => {
      if (el.data && el.data.offerStatus) {
        if (showhidden) {
          return true; // Show all offers when showhidden is true
        } else {
          return !el.markedAsHidden; // Only show unhidden offers when showhidden is false
        }
      }
    });
    columns =
      heading?.length &&
      heading.map((ele, index) => {
        if (ele?.markedAsHidden) {
          add++;
        }
        if ((Object.keys(ele).length !== 0 || ele !== null || ele !== undefined) && ele.data) {
          if (Object.keys(ele).length > 0) {
            let highlightColumn = false;
            if (String(ele.offerId) === String(urlOfferId)) {
              highlightColumn = true;
            }
            return {
              title: (
                <>
                  <div
                    style={{
                      background: ele?.markedAsHidden && showhidden ? "#84b7ee" : "",
                      height: "200px",
                      padding: "5px",
                      top: "0px",
                      left: "0px",
                      right: "0px",
                      bottom: "0px",
                      position: "absolute",
                    }}
                  >
                    <Row
                      gutter={[20, 12]}
                      justify="center"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        paddingTop: "5px",
                      }}
                    >
                      <Text
                        ellipsis={true}
                        style={{
                          color: highlightColumn ? "black" : "#fff",
                          fontWeight: "600",
                          fontSize: "18px",
                          font: "Rubik",
                          display: "flex",
                          marginLeft: "10px",
                          marginTop: "5px",
                          gap: "8px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {ele.data.offerStatus === "DRAFT" || ele.data.offerStatus === "SUBMITTED" || ele.data.offerStatus === "BACKUP" ? (
                          <Tooltip title={ele.data.offerStatus === "BACKUP" ? "Marked as backup offer" : "Mark as backup offer"}>
                            <Checkbox
                              style={{ width: "30px", height: "30px", pointerEvents: URLofferTableJSON?.urlData?.sellerOpenOfferTable ? "none" : "auto" }}
                              checked={ele.data.offerStatus === "BACKUP" ? true : false}
                              {...(checkForDisable?.disabled
                                ? { onChange: disabledTransactionText }
                                : {
                                    onChange: () => {
                                      handleOfferStatusChange({ ele, isTrue: ele.data.offerStatus === "SUBMITTED" || ele.data.offerStatus === "DRAFT" ? true : false, status: "BACKUP" });
                                    },
                                  })}
                            />
                          </Tooltip>
                        ) : ele.data.offerStatus === "ACCEPTED" ? (
                          <CheckCircleOutlined style={{ width: "30px", height: "30px", color: "#00FF29" }} />
                        ) : ele.data.offerStatus === "TERMINATED" ? (
                          <CloseCircleOutlined style={{ width: "30px", height: "30px", color: "#FF7979" }} />
                        ) : (
                          ""
                        )}
                      </Text>

                      <text className={styles.offertablenumber}>
                        {" "}
                        Offer-{getOfferNumber(ele.offerNumber)}
                        {window.screen.width > 950 && ele?.markedAsHidden ? <text style={{ fontWeight: "400", marginLeft: "3px", fontSize: "14px" }}>(Hidden)</text> : ""}
                      </text>
                      {URLofferTableJSON?.urlData?.sellerOpenOfferTable ? (
                        <div className={styles.menuitemicon}></div>
                      ) : (
                        <Col style={{ marginLeft: "-5px", padding: "3px" }}>
                          <>
                            <Dropdown key={dropdownKeys[ele.offerId]} trigger={["click", "hover"]} overlay={<MenuItem item={ele} />}>
                              <MoreOutlined className="menuitemicon" />
                            </Dropdown>
                          </>
                        </Col>
                      )}
                    </Row>
                    <Row justify="center" style={{ fontWeight: "400", fontSize: window.screen.width > 1024 ? "14px" : "12px", marginTop: window.screen.width > 1024 ? "-5px" : "-10px" }}>
                      {window.screen.width < 950 && ele?.markedAsHidden && ele.isOfferClone ? "( Hidden & Cloned )" : ele.isOfferClone ? "( Cloned )" : !ele.ispdfClone && <div style={{ visibility: "hidden" }}> Normal</div>}
                      {window.screen.width < 950 && ele?.markedAsHidden && ele.ispdfClone ? "(Hidden & Imported )" : ele.ispdfClone ? "(Imported )" : !ele.isOfferClone && <div style={{ visibility: "hidden" }}> Normal</div>}
                      {highlightColumn ? (
                        <text style={{ fontSize: window.screen.width > 1024 ? "14px" : "12px", fontWeight: "bold", color: "yellow", marginLeft: ele.isOfferClone ? "-40px" : ele.ispdfClone ? "0px" : !ele.ispdfClone && !ele.isOfferClone ? "-100px" : "0px" }} className={styles.heart}>
                          ( New Offer !)
                        </text>
                      ) : null}
                    </Row>
                  </div>
                </>
              ),
              key: uuidv4(),
              className: !ele?.markedAsHidden ? styles.otherColAlignment : styles.otherColhiddenAlignment,
              align: "center",
              render: (e, i, t) => {
                let key = firstColumnData[t];
                if (key === "offerStatus") {
                  return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                      {ele.data && (ele.data.offerStatus === "SUBMITTED" || ele.data.offerStatus === "BACKUP" || ele.data.offerStatus === "DRAFT") ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Text
                            style={{
                              color: "#4287D1",
                              fontWeight: 500,
                              fontSize: window.screen.width > 1024 ? "18px" : "15px",
                              minWidth: "75%",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              history.push({
                                pathname: `/offer/${id}/rtd/${encryptStr(ele?.offerId)}`,
                                state: {
                                  selectedData: { ...ele, fullAddress: URLofferTableJSON?.fullAddress },
                                },
                              });
                            }}
                          >
                            {ele.data.offerStatus === "SUBMITTED" || ele.data.offerStatus === "DRAFT" ? "View offer" : "Backup Offer"}
                          </Text>
                        </div>
                      ) : ele.data && ele.data.offerStatus === "ACCEPTED" ? (
                        <Button
                          onClick={() => {
                            history.push({
                              pathname: `/offer/${id}/rtd/${encryptStr(ele?.offerId)}`,
                              state: {
                                selectedData: { ...ele, fullAddress: URLofferTableJSON?.fullAddress },
                              },
                            });
                          }}
                          style={{
                            color: "#00DE24",
                            fontWeight: 500,
                            background: "rgba(0, 222, 36, 0.01)",
                            minWidth: "75%",
                            border: "1px solid #00DE24",
                            borderRadius: "5PX",
                            backgroundColor: "#4FFF6B0D",
                          }}
                        >
                          {"ACCEPTED"}
                        </Button>
                      ) : ele.data && ele.data.offerStatus === "INPROGRESS" ? (
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Button
                            onClick={() => {
                              history.push({
                                pathname: `/offer/${id}/rtd/${encryptStr(ele?.offerId)}`,
                                state: {
                                  selectedData: { ...ele, fullAddress: URLofferTableJSON?.fullAddress },
                                },
                              });
                            }}
                            style={{
                              color: "#bab710",
                              fontWeight: 600,
                              minWidth: "70%",
                              border: "2px solid #dfdc13",
                              borderRadius: "5px",
                            }}
                          >
                            IN-PROGRESS
                          </Button>
                          {ele?.counterProposalExist && <div style={{ color: "black" }}>(Countered)</div>}
                        </div>
                      ) : ele.data && ele.data.offerStatus === "TERMINATED" ? (
                        <Button
                          onClick={() => {
                            history.push({
                              pathname: `/offer/${id}/rtd/${encryptStr(ele?.offerId)}`,
                              state: {
                                selectedData: { ...ele, fullAddress: URLofferTableJSON?.fullAddress },
                              },
                            });
                          }}
                          style={{
                            color: "#FF0000",
                            fontWeight: 500,
                            background: "#f6dfdf",
                            boxShadow: "0px 0px 2px 0px #00000040",
                            minWidth: "75%",
                            border: "1px solid rgba(255, 101, 101, 0.95)",
                            borderRadius: "5px",
                          }}
                        >
                          {ele.data && ele.data.offerStatus}
                        </Button>
                      ) : ele.data && ele.data.offerStatus === "HOLD" ? (
                        <Button
                          onClick={() => {
                            history.push({
                              pathname: `/offer/${id}/rtd/${encryptStr(ele?.offerId)}`,
                              state: {
                                selectedData: { ...ele, fullAddress: URLofferTableJSON?.fullAddress },
                              },
                            });
                          }}
                          style={{
                            color: "#000000",
                            fontWeight: 600,
                            minWidth: "70%",
                            border: "2px solid #727272",
                            borderRadius: "5px",
                          }}
                        >
                          ON-HOLD
                        </Button>
                      ) : (
                        <Button
                          disabled
                          style={{
                            color: "#f74a4a",
                            border: "2px solid #f3acac",
                            fontWeight: 600,
                          }}
                        >
                          {ele.data && ele.data.offerStatus}
                        </Button>
                      )}
                      <span style={{}}>{getPropertyImages(ele)}</span>
                    </div>
                  );
                } else if (key === "Highest Escalated Price") {
                  let docId = uuidv4();
                  return <Text style={{ color: ele.markedAsHidden ? "gray" : "black" }}>{ele.data[key] === undefined || ele.data[key] === "undefined" || ele.data[key] === "" ? <CustomInputField acceptint={true} formatCol="text" placeholder="Enter Now" docId={docId} save={save} keyName={key} ele={ele} /> : <CustomInputField acceptint={true} formatCol="text" placeholder="Enter Now" docId={docId} save={save} keyName={key} ele={ele} source={"Editable"} />}</Text>;
                } else if (key === "Price" || key === "Earnest Money" || key === "Cash at Closing" || key === "Down Payment" || key === "Concessions" || key === "Possession Penalty Per Day" || key === "Rent Back Cost" || key === "Rent Back Deposit") {
                  return <Text style={{ color: ele.markedAsHidden ? "gray" : "black" }}>{formatPrice(ele.data[key]) ? `$ ${formatPrice(ele.data[key])}` : "N/A"}</Text>;
                } else if (key === "Net To Seller") {
                  return <div style={{ display: 'flex' }}>
                    <span style={{ flex: 1 }}></span>
                    <Text style={{ color: ele.markedAsHidden ? "gray" : "black", flex: 8 }}>{formatPrice(ele.data[key]) ? `$ ${formatPrice(ele.data[key])}` : "N/A"}</Text>
                    <span style={{ textAlign: "right", flex: 1, cursor: 'pointer', color: "#0036F4", }} onClick={()=>handleOpenNetSheet(ele)}><Tooltip title="View Net Sheet"><EyeOutlined className={styles.eyeIcon} /></Tooltip></span>
                  </div>;
                } else if (key === "Status Letter") {
                  return <Text style={{ color: ele.markedAsHidden ? "gray" : "black" }}>{ele.data[key] === undefined || ele.data[key] === "undefined" || ele.data[key] === "" ? "N/A" : ele.data[key]}</Text>;
                } else if (key === "Exclusions" || key === "Extra Inclusions" || key === "Additional Prov" || key === "Due Diligence Docs" || key === "Other Inclusions") {
                  let textContent = ele.data[key] === undefined || ele.data[key] === "undefined" || ele.data[key] === "" ? "N/A" : formatArrayContents(ele.data[key]);
                  return (
                    <>
                      <Expandable content={textContent} markedAsHidden={ele.markedAsHidden} />
                    </>
                  );
                } else if (key === "Inspection Objection Deadline" || key === "Inspection Termination Deadline" || key === "Loan Termination Deadline" || key === "Property Insurance Termination Deadline" || key === "Closing Date" || key === "MEC" || key === "Acceptance Date" || key === "Possession Date" || key === "Due Diligence Documents Delivery Deadline") {
                  let dateValue;
                  let dateString = "";
                  let parsedValue = parseValue(ele.data[key]);
                  if (typeof parsedValue === "string") {
                    dateValue = ele.data[key];
                  } else if (typeof parsedValue === "object") {
                    if (parsedValue.type === "date") {
                      dateValue = parsedValue.value;
                    } else if (parsedValue.type === "mec") {
                      dateString = `${parsedValue.value} days after MEC`;
                      if (parsedValue.calculatedDate) {
                        dateValue = new Date(parsedValue.calculatedDate).toISOString();
                      }
                    } else if (parsedValue.type === "text") {
                      dateString = parsedValue.value;
                    }
                  }

                  const isValidDate = dateValue && dateValue !== "undefined" && dateValue !== "Invalid Date" && !isNaN(Date.parse(dateValue));
                  if (!isValidDate && !dateString) {
                    dateString = "N/A";
                  }
                  return (
                    <Text ellipsis={false} style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {dateString && <p style={{ marginBottom: "0px" }}>{dateString}</p>}
                      {isValidDate && <p>{typeof parsedValue === "object" && parsedValue.type === "mec" ? <span style={{ color: "blue" }}>({moment(dateValue).format("MM/DD/YYYY")})</span> : moment(dateValue).format("MM/DD/YYYY")}</p>}
                    </Text>
                  );
                } else if (key === "Date Submitted") {
                  return (
                    <Text ellipsis={false} style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "" ? "N/A" : convertDate(ele.data[key], "OFFER_TABLE", key)}
                    </Text>
                  );
                } else if (key === "Time To Decide" || key === "Acceptance Time") {
                  let timeValue = "";
                  let parsedValue = parseValue(ele.data[key]);
                  if (typeof parsedValue === "string") {
                    timeValue = ele.data[key];
                  } else if (typeof parsedValue === "object") {
                    timeValue = parsedValue.value;
                  }
                  return (
                    <Text ellipsis={false} style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "" ? "N/A" : convertTime(timeValue, "OFFER_TABLE")}
                    </Text>
                  );
                } else if (key === "Broker's Name") {
                  return (
                    <Text ellipsis={false} style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {ele.transactionData?.["BuyerAgents"]?.[0]?.fullName || (ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "" ? "N/A" : parseValue(ele.data[key]))}
                    </Text>
                  );
                } else if (key === "Loan Type" || key === "Buyer" || key === "Closing Fee Paid By" || key === "Status Letter Fee Paid By" || key === "Record Change Fee Paid By" || key === "Water Transfer Fee Paid By" || key === "Items Suggested To Counter" || key === "Offer Package Supplements") {
                  return ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "" ? (
                    "N/A"
                  ) : (
                    <Text ellipsis={false} style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {parseValue(ele.data[key])}
                    </Text>
                  );
                } else {
                  let docId = uuidv4();
                  let textContent = ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "Invalid Date" || ele.data[key] === "" ? "" : ele.data[key];
                  return (
                    <Text style={{ color: ele.markedAsHidden ? "gray" : "black" }}>
                      {ele.data[key] === "undefined" || ele.data[key] === undefined || ele.data[key] === "Invalid Date" || ele.data[key] === "" ? (
                        <div>
                          <CustomInputField formatCol="text" placeholder="Enter Now" docId={docId} save={save} keyName={key} ele={ele} />
                        </div>
                      ) : (
                        <Expandable content={textContent} source="Editable" keyName={key} docId={docId} save={save} ele={ele} markedAsHidden={ele.markedAsHidden} />
                      )}
                    </Text>
                  );
                }
              },
            };
          }
        }
      });

    //get length of first record of the offerdata
    let noOfRows = firstColumnData?.length;

    //get length of records in the offer table
    let noOfColoumns = rowData.length;

    // creates m * n matrix for the table. Pushes blank object if it
    if (noOfRows > noOfColoumns) {
      for (let index = 0; index < noOfRows - noOfColoumns; index++) {
        rowData.push({ offerId: index + 1 });
      }
    }

    columns.unshift(firstColumn);
  }

  let mergedColumns = columns.map((ele) => {
    return ele && Object.keys(ele).length > 0 ? ele : {};
  });
  return (
    <>
  {(isEmailModalVisible?.isEmailModalOpen) && (<SignableAndReadOnly source="SHARE_INDIVIDUAL_OFFER" selectedData={selectedD?.current} documentId={selectedD?.current?.CBSRId} isEmailModalVisible={isEmailModalVisible} setIsEmailModalVisible={setIsEmailModalVisible} />)}

      <AddCriteria addCriteriaModalOpen={addCriteriaModalOpen} setAddCriteriaModalOpen={setAddCriteriaModalOpen} sort={sort} order={order} />
      <Layout style={{ width: "-webkit-fill-available" }}>
        {offerTableErrorData ? (
          <div
            style={{
              height: "100vh",
              background: "white",
            }}
          >
            <SomeThingWentWrong message={offerTableErrorData} flag={false} />
          </div>
        ) : offerTableloading || offerTableCustomLoading ? (
          <Loading />
        ) : (offerTableData && offerTableData?.length < 0) || offerTableData == null ? (
          <>
            <Title
              style={{
                height: "100vh",
                width: "-webkit-fill-available",
                textAlign: "center",
                top: "40%",
                position: "fixed",
              }}
              level={4}
              className={styles.titleAlignment}
            >
              {offerTableData == null ? "No offers for this listings" : "Offer should be more than one for this listing"}
            </Title>
          </>
        ) : (
          <>
            <Content className={styles.contentAlign}>
              <div className={`${styles.tableWidth} ${mergedColumns && mergedColumns.length === 2 ? styles.singleColOfferTable : mergedColumns && mergedColumns.length === 3 ? styles.doubleColOfferTable : mergedColumns && mergedColumns.length === 4 ? styles.threeColOfferTable : mergedColumns && mergedColumns.length > 4 ? styles.moreColOfferTable : ""}`}>
                <Table
                 rowKey={(record) => record.offerId}
                  ref={tableRef}
                  bordered
                  columns={mergedColumns}
                  dataSource={offerTableData}
                  pagination={false}
                  scroll={{
  x: "max-content", // Set the horizontal scroll to 'max-content' or a specific width
  y: window.screen.width < 480 ? "auto" : (window.screen.width >= 480 && window.screen.width <= 1080) ? "auto" : "65vh", // Set the vertical scroll to 'auto' if width is between 480 and 1024, otherwise set to '60vh'
}}

                  className={styles.offertableheight}

                />
              </div>
            </Content>
          </>
        )}
      </Layout>
    </>
  );
});

export { OfferTableRender };
